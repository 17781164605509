
export const STATUS_APPROVED = 1;
export const STATUS_PENDING = 2;
export const STATUS_REJECTED = 3;

const LABEL_APPROVED = 'Approved';
const LABEL_PENDING = 'Pending';
const LABEL_REJECTED = 'Rejected';
export const LABEL_BY_STATUS = {
    [STATUS_APPROVED]: LABEL_APPROVED,
    [STATUS_PENDING]: LABEL_PENDING,
    [STATUS_REJECTED]: LABEL_REJECTED,
};
